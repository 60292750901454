import { useState } from "react";
import React, { createContext, useContext, useEffect } from "react";
import { Provider } from "react-redux";
import { productItemHome } from "../../pages/home";
import { productData } from "../../pages/products/mockData";
import { store } from "../store";
import Banner from "./banner/Banner";
import BestSeller from "./best-seller/BestSeller";
import Brands from "./brands/Brands";
import CategoriesItems from "./categories-items/CategoriesItems";
import CostInfo from "./cost-info/CostInfo";
import FeaturedProducts from "./featured-products/FeaturedProducts";
import Network from "./network/Network";
import ProductsCategory from "./products-category/ProductsCategory";
import ProductItem from "./products/product/ProductItem";
import Products from "./products/Products";
import ServiceList from "./service-list/ServiceList";
import TodayDeals from "./today-deals/TodayDeals";
import TrendingProducts from "./trending-products/TrendingProducts";
import AddingShow from "./network/AddingShow";
import HandicraftImage from "./handicraft-image/HandicraftImage";
import NewsLetter from "./news-letter/NewsLetter";
import Catalog from "./catalog/Catalog";
import PolicyBlock from "./policy-block/PolicyBlock";
import SwitchContent from "./switch-content/SwitchContent";
import VisualCategory from "./visual-category/VisualCategory";
import CollectionProduct from "./collection-product/CollectionProduct";
import Testimonials from "./testimonials/Testimonials";
import SpiritBrand from "./spirit-brand/SpiritBrand";
import ShopSocial from "./shop-social/ShopSocial";
function Home() {
  const productData = useContext(productItemHome);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Banner />
      <PolicyBlock />
      <Catalog />
      {/* <FeaturedProducts /> */}
      <SwitchContent />

      <VisualCategory />
      <CollectionProduct />

      {/* <ServiceList /> */}
      {/* <ProductsCategory /> */}

      {/* <Products productData={productData} /> */}

      {/* <TrendingProducts /> */}
      {/* <TodayDeals /> */}
      <section className="sellerCategorySec d-none">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <CategoriesItems />
            </div>
          </div>
        </div>
      </section>
      <Brands />
      {/* <BestSeller /> */}
      <Network />

      <AddingShow />
      <SpiritBrand />
      <ShopSocial />
      <NewsLetter />
      <Testimonials />


      {/* <HandicraftImage /> */}
      {/* <CostInfo /> */}
    </>
  );
}

export default Home;
